import React from "react"
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import swagger from '../data/swagger.json'

function Swagger(props) {

    let data = swagger;

    return (
        <>
            <SwaggerUI
                spec={data}
                displayRequestDuration={true}
                requestInterceptor={(request) => {
                    request.headers.Authorization = `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`;
                    return request;
                }
                }
                responseInterceptor={(response) => {
                    return response
                }}
            />
        </>
    )
}

export default Swagger;