import React from 'react';
import './App.css';
import { httpClient } from './HttpClient';

import Keycloak from 'keycloak-js';
import Swagger from './component/swagger';

let initOptions = {
  url: "https://sso.golem-tech.io/",
  realm: 'golemgpt',
  clientId: "golem-llm-frontend",
}

let kc = new Keycloak(initOptions);

kc.init({
  onLoad: 'login-required',
  checkLoginIframe: true,
  pkceMethod: 'S256'
}).then((auth) => {
  console.log(auth)
  if (!auth) {
    window.location.reload();
  } else {
    localStorage.setItem('ACCESS_TOKEN', kc.token)
    httpClient.defaults.headers.common['Authorization'] = `Bearer ${kc.token}`;
    kc.onTokenExpired = () => {
      console.log('token expired')
      kc.refreshToken()
      localStorage.setItem('ACCESS_TOKEN', kc.token)
    }
  }
}, () => {
  /* Notify the user if necessary */
  console.error("Authentication Failed");
});

function App() {

  return (
    <div className="App">
     <Swagger/>
    </div>
  );
}


export default App;
